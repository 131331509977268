import { PUBLIC_MAIN_ROUTES } from '@/v2/constants/routes/main-routes'

export const FOOTER = Object.freeze({
  items: [
    {
      id: 1,
      title: 'STORAGE SERVICES',
      className: '',
      children: [
        {
          id: 1,
          url: '/residential-storage',
          label: 'Residential Storage Solution'
        },
        {
          id: 2,
          url: '/business-storage',
          label: 'Business Storage Solution'
        },
        {
          id: 3,
          url: '/moving-storage-solution',
          label: 'Moving Storage Solution'
        },
        {
          id: 4,
          url: PUBLIC_MAIN_ROUTES.COLLEGE_STORAGE_SERVICES.href,
          label: PUBLIC_MAIN_ROUTES.COLLEGE_STORAGE_SERVICES.title
        },
        {
          id: 5,
          url: PUBLIC_MAIN_ROUTES.DROP_OFF_RESIDENTIAL_STORAGE.href,
          label: 'Drop Off The Storage'
        },
        {
          id: 6,
          url: PUBLIC_MAIN_ROUTES.PICKUP_FROM_STORAGE.href,
          label: PUBLIC_MAIN_ROUTES.PICKUP_FROM_STORAGE.title
        },
        {
          id: 7,
          url: PUBLIC_MAIN_ROUTES.LUGGAGE_STORAGE.href,
          label: 'Luggage Storage Services'
        },
        {
          id: 8,
          url: PUBLIC_MAIN_ROUTES.MOVING_BOX_STORAGE.href,
          label: 'Moving Box Storage'
        },
        {
          id: 9,
          url: PUBLIC_MAIN_ROUTES.GOLF_CLUB_STORAGE.href,
          label: 'Golf Club Storage'
        },
        {
          id: 10,
          url: PUBLIC_MAIN_ROUTES.SNOWBOARD_STORAGE.href,
          label: 'Snowboard Storage'
        },
        {
          id: 11,
          url: PUBLIC_MAIN_ROUTES.SKI_GEAR_STORAGE.href,
          label: 'Ski Gear Storage'
        }
      ]
    },
    {
      id: 2,
      title: 'SHIPPING SERVICES',
      className: '',
      children: [
        {
          id: 1,
          url: PUBLIC_MAIN_ROUTES.DOMESTIC_SHIPPING_SERVICES.href,
          label: 'Domestic Shipping Services'
        },
        {
          id: 2,
          url: PUBLIC_MAIN_ROUTES.INTERNATIONAL_SHIPPING_SERVICES.href,
          label: 'International Shipping Services'
        },
        {
          id: 3,
          url: PUBLIC_MAIN_ROUTES.SHIPPING_TO_STORAGE.href,
          label: 'Shipping To Storage'
        },
        {
          id: 4,
          url: PUBLIC_MAIN_ROUTES.LUGGAGE_SHIPPING.href,
          label: 'Luggage Shipping'
        },
        {
          id: 5,
          url: PUBLIC_MAIN_ROUTES.MOVING_BOX_SHIPPING.href,
          label: 'Moving Box Shipping'
        },
        {
          id: 6,
          url: '/',
          label: 'Golf Club Shipping'
        },
        {
          id: 7,
          url: PUBLIC_MAIN_ROUTES.SNOWBOARD_SHIPPING.href,
          label: 'Snowboard Shipping'
        },
        {
          id: 8,
          url: PUBLIC_MAIN_ROUTES.SKI_GEAR_SHIPPING.href,
          label: 'Ski Gear Shipping'
        },
        {
          id: 9,
          url: PUBLIC_MAIN_ROUTES.COLLEGE_STUDENT_SHIPPING.href,
          label: 'College Student Shipping'
        },
        {
          id: 10,
          url: PUBLIC_MAIN_ROUTES.SUMMER_CAMP_SHIPPING.href,
          label: 'Summer Camp Shipping'
        }
      ]
    },
    {
      id: 3,
      title: 'KNOWLEDGE BASE',
      className: '',
      children: [
        {
          id: 1,
          url: PUBLIC_MAIN_ROUTES.HOW_STORAGE_SERVICES_WORKS.href,
          label: 'How Storage Services Works'
        },
        {
          id: 2,
          url: PUBLIC_MAIN_ROUTES.HOW_SHIP_AND_STORAGE_WORKS.href + '?tab=2',
          label: 'How Shipping Services Works'
        },
        {
          id: 3,
          url: PUBLIC_MAIN_ROUTES.HOW_TO_ATTACH_SHIPPING_LABELS.href,
          label: 'How To Attach Shipping Labels'
        },
        {
          id: 4,
          url: PUBLIC_MAIN_ROUTES.USE_CORRECT_PACKING_MATERIAL.href,
          label: 'Use Correct Packing Material'
        },
        {
          id: 5,
          url: PUBLIC_MAIN_ROUTES.SHIPPING_WEIGHT_AND_SIZES.href,
          label: 'Shipping Weight and Sizes'
        },
        {
          id: 6,
          url: PUBLIC_MAIN_ROUTES.COST_SAVING_TIPS_ON_SHIPPING.href,
          label: 'Cost Saving Tips on Shipping '
        },
        {
          id: 7,
          url: PUBLIC_MAIN_ROUTES.SHIPPING_INSURANCE.href,
          label: 'Shipping Insurance'
        },
        {
          id: 8,
          url: PUBLIC_MAIN_ROUTES.WHAT_WE_SHIP.href,
          label: 'What We Ship'
        },
        {
          id: 9,
          url: '/',
          label: 'What We Store'
        },
        {
          id: 10,
          url: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP.href,
          label: 'Prohibited Items to Ship',
          className: 'text-red-500 font-semibold'
        },
        {
          id: 11,
          url: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP.href,
          label: 'Prohibited Items to Store',
          className: 'text-red-500 font-semibold'
        }
      ]
    },
    {
      id: 4,
      title: 'QUICK LINKS',
      className: '',
      children: [
        {
          id: 1,
          url: '/',
          label: 'My Rewards'
        },
        {
          id: 2,
          url: PUBLIC_MAIN_ROUTES.ORDER_PACKING_MATERIAL.href,
          label: 'Order Packing Material'
        },
        {
          id: 3,
          url: PUBLIC_MAIN_ROUTES.LUGGAGE_TRACKING.href,
          label: 'Track Your Shipping'
        },
        {
          id: 4,
          url: PUBLIC_MAIN_ROUTES.DROP_OFF_STORAGE_LOCATIONS.href,
          label: 'Drop off Location'
        },
        {
          id: 5,
          url: PUBLIC_MAIN_ROUTES.LUGGAGE_AND_QUESTION.href,
          label: 'General FAQ'
        }
      ]
    },
    {
      id: 5,
      title: 'COMPANY',
      className: '',
      children: [
        {
          id: 1,
          url: PUBLIC_MAIN_ROUTES.ABOUT_US.href,
          label: 'About Us'
        },
        {
          id: 2,
          url: PUBLIC_MAIN_ROUTES.CONTACT.href,
          label: 'Contact Us'
        },
        {
          id: 3,
          url: '/',
          label: 'News & Press'
        },
        {
          id: 4,
          url: '/',
          label: 'Shipping Partners'
        },
        {
          id: 5,
          url: '/',
          label: 'Business program'
        },
        {
          id: 6,
          url: '/',
          label: 'Partner login'
        },
        {
          id: 7,
          url: PUBLIC_MAIN_ROUTES.PRIVACY_POLICY.href,
          label: PUBLIC_MAIN_ROUTES.PRIVACY_POLICY.title
        },
        {
          id: 8,
          url: PUBLIC_MAIN_ROUTES.TERMS_AND_CONDITIONS.href,
          label: 'Terms of Use'
        }
      ]
    }
  ]
})
