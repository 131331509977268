import Business1 from '~/public/icons/top-menu/business/business1.svg'
import Business10 from '~/public/icons/top-menu/business/business10.svg'
import Business11 from '~/public/icons/top-menu/business/business11.svg'
import Business12 from '~/public/icons/top-menu/business/business12.svg'
import Business2 from '~/public/icons/top-menu/business/business2.svg'
import Business3 from '~/public/icons/top-menu/business/business3.svg'
import Business4 from '~/public/icons/top-menu/business/business4.svg'
import Business5 from '~/public/icons/top-menu/business/business5.svg'
import Business6 from '~/public/icons/top-menu/business/business6.svg'
import Business7 from '~/public/icons/top-menu/business/business7.svg'
import Business8 from '~/public/icons/top-menu/business/business8.svg'
import Business9 from '~/public/icons/top-menu/business/business9.svg'
import Residential1 from '~/public/icons/top-menu/residential/residential1.svg'
import Residential10 from '~/public/icons/top-menu/residential/residential10.svg'
import Residential11 from '~/public/icons/top-menu/residential/residential11.svg'
import Residential12 from '~/public/icons/top-menu/residential/residential12.svg'
import Residential2 from '~/public/icons/top-menu/residential/residential2.svg'
import Residential3 from '~/public/icons/top-menu/residential/residential3.svg'
import Residential4 from '~/public/icons/top-menu/residential/residential4.svg'
import Residential5 from '~/public/icons/top-menu/residential/residential5.svg'
import Residential6 from '~/public/icons/top-menu/residential/residential6.svg'
import Residential7 from '~/public/icons/top-menu/residential/residential7.svg'
import Residential8 from '~/public/icons/top-menu/residential/residential8.svg'
import Residential9 from '~/public/icons/top-menu/residential/residential9.svg'

import college1 from '~/public/icons/top-menu/college/college1.svg'
import college10 from '~/public/icons/top-menu/college/college10.svg'
import college11 from '~/public/icons/top-menu/college/college11.svg'
import college12 from '~/public/icons/top-menu/college/college12.svg'
import college2 from '~/public/icons/top-menu/college/college2.svg'
import college3 from '~/public/icons/top-menu/college/college3.svg'
import college4 from '~/public/icons/top-menu/college/college4.svg'
import college5 from '~/public/icons/top-menu/college/college5.svg'
import college6 from '~/public/icons/top-menu/college/college6.svg'
import college7 from '~/public/icons/top-menu/college/college7.svg'
import college8 from '~/public/icons/top-menu/college/college8.svg'
import college9 from '~/public/icons/top-menu/college/college9.svg'

import sports1 from '~/public/icons/top-menu/sports/sports1.png'
import sports10 from '~/public/icons/top-menu/sports/sports10.svg'
import sports11 from '~/public/icons/top-menu/sports/sports11.svg'
import sports12 from '~/public/icons/top-menu/sports/sports12.svg'
import sports2 from '~/public/icons/top-menu/sports/sports2.svg'
import sports3 from '~/public/icons/top-menu/sports/sports3.svg'
import sports4 from '~/public/icons/top-menu/sports/sports4.png'
import sports5 from '~/public/icons/top-menu/sports/sports5.svg'
import sports6 from '~/public/icons/top-menu/sports/sports6.svg'
import sports7 from '~/public/icons/top-menu/sports/sports7.svg'
import sports8 from '~/public/icons/top-menu/sports/sports8.svg'
import sports9 from '~/public/icons/top-menu/sports/sports9.svg'

import shipping1 from '~/public/icons/top-menu/shipping/shipping1.svg'
import shipping10 from '~/public/icons/top-menu/shipping/shipping10.svg'
import shipping11 from '~/public/icons/top-menu/shipping/shipping11.svg'
import shipping12 from '~/public/icons/top-menu/shipping/shipping12.svg'
import shipping2 from '~/public/icons/top-menu/shipping/shipping2.svg'
import shipping3 from '~/public/icons/top-menu/shipping/shipping3.svg'
import shipping4 from '~/public/icons/top-menu/shipping/shipping4.svg'
import shipping5 from '~/public/icons/top-menu/shipping/shipping5.svg'
import shipping6 from '~/public/icons/top-menu/shipping/shipping6.svg'
import shipping7 from '~/public/icons/top-menu/shipping/shipping7.svg'
import shipping8 from '~/public/icons/top-menu/shipping/shipping8.svg'
import shipping9 from '~/public/icons/top-menu/shipping/shipping9.svg'

import { PUBLIC_MAIN_ROUTES } from '@/v2/constants/routes/main-routes'
import company1 from '~/public/icons/top-menu/company/company1.svg'
import company2 from '~/public/icons/top-menu/company/company2.svg'
import company3 from '~/public/icons/top-menu/company/company3.svg'
import company4 from '~/public/icons/top-menu/company/company4.svg'
import company5 from '~/public/icons/top-menu/company/company5.svg'
import company6 from '~/public/icons/top-menu/company/company6.svg'

export const MainMenus = [
  {
    id: 1,
    title: 'Residential Storage',
    url: '/residential-storage',
    content:
      '<p class="max-w-[210px]">Store household essentials securely with flexible plans, pickup, and delivery services for moving, renovations, or emergencies.</p>',
    children: [
      {
        id: 1,
        icon: Residential1,
        url: '/residential-storage',
        label: 'Residential Storage',
        content: '<p class="max-w-[218px]">Store home essentials easily and safely.</p>'
      },
      {
        id: 2,
        icon: Residential2,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_TO_STORAGE.href,
        label: 'Shipping To storage',
        content: '<p class="max-w-[192px]">Send items straight to storage units.</p>'
      },
      {
        id: 3,
        icon: Residential3,
        url: PUBLIC_MAIN_ROUTES.HOW_SHIP_AND_STORAGE_WORKS.href,
        label: 'How Ship & Storage Works',
        content: '<p class="max-w-[268px]">Simple steps for smooth storage solution.</p>'
      },
      {
        id: 4,
        icon: Residential4,
        url: PUBLIC_MAIN_ROUTES.RESIDENTIAL_STORAGE_FEE.href,
        label: 'Residential Storage Fee',
        content: '<p class="max-w-[196px]">Transparent and fair pricing for all.</p>'
      },
      {
        id: 5,
        icon: Residential5,
        url: PUBLIC_MAIN_ROUTES.DROP_OFF_RESIDENTIAL_STORAGE.href,
        label: 'Drop Off The Storage',
        content: '<p class="max-w-[184px]">Quick and easy item drop-offs.</p>'
      },
      {
        id: 6,
        icon: Residential6,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_TIPS.href,
        label: 'Storage & Shipping Tips',
        content: '<p class="max-w-[262px]">Smart tips for better storage service.</p>'
      },
      {
        id: 7,
        icon: Residential7,
        url: PUBLIC_MAIN_ROUTES.MOVING_BOX_STORAGE.href,
        label: 'Moving Box Storage',
        content: '<p class="max-w-[226px]">Keep moving boxes safe and organised.</p>'
      },
      {
        id: 8,
        icon: Residential8,
        url: PUBLIC_MAIN_ROUTES.PICKUP_FROM_STORAGE.href,
        label: 'Pickup From Storage',
        content: '<p class="max-w-[232px]">Convenient pickups whenever needed.</p>'
      },
      {
        id: 9,
        icon: Residential9,
        url: PUBLIC_MAIN_ROUTES.STORAGE_WEIGHT_AND_SIZE_LIMIT.href,
        label: 'Storage Weight and Size Limit',
        content: '<p class="max-w-[268px]">Follow size rules for storage.</p>'
      },
      {
        id: 10,
        icon: Residential10,
        url: PUBLIC_MAIN_ROUTES.LUGGAGE_SHIPPING.href,
        label: 'Luggage Storage',
        content: '<p class="max-w-[212px]">Secure storage for your travel luggage.</p>'
      },
      {
        id: 11,
        icon: Residential11,
        url: PUBLIC_MAIN_ROUTES.WHAT_WE_STORE.href,
        label: 'What we store',
        content: '<p class="max-w-[226px]">Store luggage, boxes, and more securely.</p>'
      },
      {
        id: 12,
        icon: Residential12,
        url: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP.href,
        label: 'Prohibited Items To Store',
        content: '<p class="max-w-[232px]">Avoid storing restricted goods.</p>'
      }
    ]
  },
  {
    id: 2,
    title: 'Business Storage',
    url: '/business-storage',
    content:
      '<p class="max-w-[210px]">Reliable storage solutions for businesses, including seasonal stock, office equipment, and promotional materials, with easy management.</p>',
    children: [
      {
        id: 1,
        url: '/business-storage',
        icon: Business1,
        label: 'Business Storage',
        content: '<p class="max-w-[218px]">Tailored solutions for your business needs.</p>'
      },
      {
        id: 2,
        icon: Business2,
        url: PUBLIC_MAIN_ROUTES.OFFICE_EQUIPMENT_STORAGE.href,
        label: 'Office Equipment Storage',
        content: '<p class="max-w-[218px]">Store office gear with care.</p>'
      },
      {
        id: 3,
        icon: Business3,
        url: PUBLIC_MAIN_ROUTES.HOW_SHIP_AND_STORAGE_WORKS.href,
        label: 'How Ship & Storage Works',
        content: '<p class="max-w-[230px]">Easy steps for business storage.</p>'
      },
      {
        id: 4,
        icon: Business4,
        url: '/business-storage-fee',
        label: 'Business Storage Fee',
        content: '<p class="max-w-[218px]">Flexible pricing for smart business storage.</p>'
      },
      {
        id: 5,
        icon: Business5,
        url: PUBLIC_MAIN_ROUTES.TRADE_SHOW_STORAGE.href,
        label: 'Trade Show Storage',
        content: '<p class="max-w-[222px]">Store event materials between shows</p>'
      },
      {
        id: 6,
        icon: Business6,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_TIPS.href,
        label: 'Storage & Shipping Tips',
        content: '<p class="max-w-[218px]">Handy tips to store wisely.</p>'
      },
      {
        id: 7,
        icon: Business7,
        url: PUBLIC_MAIN_ROUTES.DOCUMENT_STORAGE_AND_ARCHIVING.href,
        label: 'Document Storage & Archiving',
        content: '<p class="max-w-[218px]">Keep business records safe.</p>'
      },
      {
        id: 8,
        icon: Business8,
        url: '/business-storage',
        label: 'Promotional Material Storage',
        content: '<p class="max-w-[218px]">Safely store promo tools.</p>'
      },
      {
        id: 9,
        icon: Business9,
        url: PUBLIC_MAIN_ROUTES.STORAGE_WEIGHT_AND_SIZE_LIMIT.href,
        label: 'Storage Weight and Size Limit',
        content: '<p class="max-w-[218px]">Adhere to weight & size restrictions.</p>'
      },
      {
        id: 10,
        icon: Business10,
        url: PUBLIC_MAIN_ROUTES.SEASONAL_ITEMS_STORAGE.href,
        label: 'Seasonal Item Storage',
        content: '<p class="max-w-[218px]">Secure seasonal products until needed.</p>'
      },
      {
        id: 11,
        icon: Business11,
        url: PUBLIC_MAIN_ROUTES.PRODUCT_SAMPLE_STORAGE.href,
        label: 'Product sample Storage',
        content: '<p class="max-w-[218px]">Keep product samples organised.</p>'
      },
      {
        id: 12,
        icon: Business12,
        url: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP.href,
        label: 'Prohibited Items To Store',
        content: '<p class="max-w-[218px]">Know items you can’t store at storage.</p>'
      }
    ]
  },
  {
    id: 3,
    title: 'College Storage',
    url: PUBLIC_MAIN_ROUTES.COLLEGE_STORAGE_SERVICES.href,
    content:
      '<p class="max-w-[218px]">Hassle-free storage for students, securing dorm items, books, and luggage during breaks with easy drop-off and pickup options.</p>',
    children: [
      {
        id: 1,
        icon: college1,
        url: PUBLIC_MAIN_ROUTES.COLLEGE_STORAGE_SERVICES.href,
        label: 'College Storage',
        content: '<p class="max-w-[218px]">Easy and safe storage for students</p>'
      },
      {
        id: 2,
        icon: college2,
        url: PUBLIC_MAIN_ROUTES.MOVING_BOX_STORAGE.href,
        label: 'Moving Box Storage',
        content: '<p class="max-w-[218px]">Safe storage for student boxes.</p>'
      },
      {
        id: 3,
        icon: college3,
        url: PUBLIC_MAIN_ROUTES.HOW_SHIP_AND_STORAGE_WORKS.href,
        label: 'How Ship & Storage Works',
        content: '<p class="max-w-[218px]">Simple process for students.</p>'
      },
      {
        id: 4,
        icon: college4,
        url: '/college-storage-fee',
        label: 'College Storage Fee',
        content: '<p class="max-w-[218px]">Student-friendly rates for storage.</p>'
      },
      {
        id: 5,
        icon: college5,
        url: PUBLIC_MAIN_ROUTES.LUGGAGE_STORAGE.href,
        label: 'Luggage Storage',
        content: '<p class="max-w-[218px]">Keep luggage secure and accessible.</p>'
      },
      {
        id: 6,
        icon: college6,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_TIPS.href,
        label: 'Storage & Shipping Tips',
        content: '<p class="max-w-[218px]">Smart advice for students.</p>'
      },
      {
        id: 7,
        icon: college7,
        url: '/dorm-room-storage',
        label: 'Dorm Room Storage',
        content: '<p class="max-w-[218px]">Store dorm items over breaks.</p>'
      },
      {
        id: 8,
        icon: college8,
        url: PUBLIC_MAIN_ROUTES.DROP_OFF_COLLEGE_STORAGE.href,
        label: 'Drop Off The Storage',
        content: '<p class="max-w-[218px]">Quick drop-offs for students.</p>'
      },
      {
        id: 9,
        icon: college9,
        url: '/college-storage-services',
        label: 'Storage Weight and Size Limit',
        content: '<p class="max-w-[218px]">Know your limits beforehand.</p>'
      },
      {
        id: 10,
        icon: college10,
        url: PUBLIC_MAIN_ROUTES.WHAT_WE_STORE.href,
        label: 'What we store',
        content: '<p class="max-w-[218px]">We Store luggage, boxes, and more safely.</p>'
      },
      {
        id: 11,
        icon: college11,
        url: PUBLIC_MAIN_ROUTES.PICKUP_FROM_STORAGE.href,
        label: 'Pickup From Storage',
        content: '<p class="max-w-[218px]">Convenient storage pickups on time.</p>'
      },
      {
        id: 12,
        icon: college12,
        url: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP.href,
        label: 'Prohibited Items To Store',
        content: '<p class="max-w-[218px]">Avoid restricted student items.</p>'
      }
    ]
  },
  {
    id: 4,
    title: 'Sport Gear Storage',
    url: '/moving-storage-solution',
    content:
      '<p class="max-w-[218px]">Safely store sports gear like golf bags and ski equipment, with convenient shipping, drop-off, and pickup services.</p>',
    children: [
      {
        id: 1,
        icon: sports1,
        url: '/sport-gear-storage',
        label: 'Sport Gear Storage',
        content: '<p>Safe storage for your gear.</p>'
      },
      {
        id: 2,
        icon: sports2,
        url: PUBLIC_MAIN_ROUTES.SNOWBOARD_STORAGE.href,
        label: 'Snowboard Storage',
        content: '<p class="max-w-[218px]">Store snowboards with care.</p>'
      },
      {
        id: 3,
        icon: sports3,
        url: PUBLIC_MAIN_ROUTES.HOW_SHIP_AND_STORAGE_WORKS.href,
        label: 'How Ship & Storage Works',
        content: '<p class="max-w-[218px]">Simple gear storage process.</p>'
      },
      {
        id: 4,
        icon: sports4,
        url: '/gear-storage-fee',
        label: 'Gear Storage Fee',
        content: '<p class="max-w-[218px]">Affordable rates for gear storage.</p>'
      },
      {
        id: 5,
        icon: sports5,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_TO_STORAGE.href,
        label: 'Shipping To Storage',
        content: '<p class="max-w-[218px]">Ship sports gear to storage.</p>'
      },
      {
        id: 6,
        icon: sports6,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_TIPS.href,
        label: 'Storage & Shipping Tips',
        content: '<p class="max-w-[218px]">Tips for storing sports gear.</p>'
      },
      {
        id: 7,
        icon: sports7,
        url: PUBLIC_MAIN_ROUTES.GOLF_CLUB_STORAGE.href,
        label: 'Golf Bag Storage',
        content: '<p class="max-w-[218px]">Store golf bags securely.</p>'
      },
      {
        id: 8,
        icon: sports8,
        url: PUBLIC_MAIN_ROUTES.DROP_OFF_SPORTS_GEAR_STORAGE.href,
        label: 'Drop Off The Storage',
        content: '<p class="max-w-[218px]">Easy drop-off for gear.</p>'
      },
      {
        id: 9,
        icon: sports9,
        url: '/moving-storage-solution',
        label: 'Storage Weight and Size Limit',
        content: '<p class="max-w-[218px]">Follow storage rules carefully.</p>'
      },
      {
        id: 10,
        icon: sports10,
        url: PUBLIC_MAIN_ROUTES.SKI_GEAR_STORAGE.href,
        label: 'Ski Gear Storage',
        content: '<p class="max-w-[218px]">Keep ski gear safe for trips.</p>'
      },
      {
        id: 11,
        icon: sports11,
        url: PUBLIC_MAIN_ROUTES.PICKUP_FROM_STORAGE.href,
        label: 'Pickup From Storage',
        content: '<p class="max-w-[218px]">Quick pickups for stored gear.</p>'
      },
      {
        id: 12,
        icon: sports12,
        url: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP.href,
        label: 'Prohibited Items To Store',
        content: '<p class="max-w-[218px]">Know gear restrictions.</p>'
      }
    ]
  },
  {
    id: 5,
    title: 'Shipping Services',
    url: '/luggage-box-shipping-services',
    content:
      '<p class="max-w-[218px]">Fast, reliable shipping services, offering both domestic and international delivery for luggage, boxes, and sports equipment.</p>',
    children: [
      {
        id: 1,
        icon: shipping1,
        url: PUBLIC_MAIN_ROUTES.DOMESTIC_SHIPPING_SERVICES.href,
        label: 'Domestic Shipping',
        content: '<p class="max-w-[286px]">Fast shipping across the country.</p>'
      },
      {
        id: 2,
        icon: shipping2,
        url: PUBLIC_MAIN_ROUTES.MOVING_BOX_SHIPPING.href,
        label: 'Moving Box Shipping',
        content: '<p class="max-w-[218px]">Safely ship your moving boxes.</p>'
      },
      {
        id: 3,
        icon: shipping3,
        url: PUBLIC_MAIN_ROUTES.HOW_SHIP_AND_STORAGE_WORKS.href + '?tab=2',
        label: 'How Shipping Services Works',
        content: '<p class="max-w-[218px]">Simple process explained.</p>'
      },
      {
        id: 4,
        icon: shipping4,
        url: PUBLIC_MAIN_ROUTES.INTERNATIONAL_SHIPPING_SERVICES.href,
        label: 'International Shipping',
        content: '<p class="max-w-[218px]">Ship items globally with ease.</p>'
      },
      {
        id: 5,
        icon: shipping5,
        url: '/luggage-box-shipping-services',
        label: 'Golf Club Shipping',
        content: '<p class="max-w-[218px]">Ship golf clubs with confidence.</p>'
      },
      {
        id: 6,
        icon: shipping6,
        url: PUBLIC_MAIN_ROUTES.COST_SAVING_TIPS_ON_SHIPPING.href,
        label: 'Cost Saving Tips on Shipping',
        content: '<p class="max-w-[218px]">Save on every shipment.</p>'
      },
      {
        id: 7,
        icon: shipping7,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_TO_STORAGE.href,
        label: 'Shipping To Storage',
        content: '<p class="max-w-[218px]">Send items to storage units.</p>'
      },
      {
        id: 8,
        icon: shipping8,
        url: PUBLIC_MAIN_ROUTES.SKI_GEAR_SHIPPING.href,
        label: 'Ski Gear Shipping',
        content: '<p class="max-w-[218px]">Smooth ski gear shipping.</p>'
      },
      {
        id: 9,
        icon: shipping9,
        url: PUBLIC_MAIN_ROUTES.SHIPPING_WEIGHT_AND_SIZES.href,
        label: 'Shipping Weight and Size Limit',
        content: '<p class="max-w-[218px]">Stay within shipping rules.</p>'
      },
      {
        id: 10,
        icon: shipping10,
        url: PUBLIC_MAIN_ROUTES.LUGGAGE_SHIPPING.href,
        label: 'Luggage Shipping',
        content: '<p class="max-w-[218px]">Ship your luggage safely.</p>'
      },
      {
        id: 11,
        icon: shipping11,
        url: PUBLIC_MAIN_ROUTES.SNOWBOARD_SHIPPING.href,
        label: 'Snowboard Shipping',
        content: '<p class="max-w-[218px]">Ship snowboards with ease.</p>'
      },
      {
        id: 12,
        icon: shipping12,
        url: PUBLIC_MAIN_ROUTES.WHAT_CANT_SHIP.href,
        label: 'Prohibited Items To Ship',
        content: '<p class="max-w-[218px]">Avoid banned Item shipments.</p>'
      }
    ]
  },
  {
    id: 6,
    title: 'Company',
    url: '/about-us',
    content:
      '<p class="max-w-[218px]">Explore who we are, stay updated on business programs and news, and discover our policies and contact options.</p>',
    children: [
      {
        id: 1,
        icon: company1,
        url: PUBLIC_MAIN_ROUTES.ABOUT_US.href,
        label: 'About Us',
        content: '<p class="max-w-[220px]">Discover who we are and what we do.</p>'
      },
      {
        id: 2,
        icon: company2,
        url: '/',
        label: 'News And Press',
        content: '<p class="max-w-[162px]">Latest updates and announcements.</p>'
      },
      {
        id: 3,
        icon: company3,
        url: PUBLIC_MAIN_ROUTES.CONTACT.href,
        label: 'Contact Us',
        content: '<p class="max-w-[218px]">Reach out for any assistance.</p>'
      },
      {
        id: 4,
        icon: company4,
        url: '/',
        label: 'Business Program',
        content: '<p class="max-w-[218px]">Explore business solutions with us.</p>'
      },
      {
        id: 5,
        icon: company5,
        url: PUBLIC_MAIN_ROUTES.PRIVACY_POLICY.href,
        label: 'Privacy Policy',
        content: '<p class="max-w-[218px]">Your data is safe with us.</p>'
      },
      {
        id: 6,
        icon: company6,
        url: PUBLIC_MAIN_ROUTES.TERMS_AND_CONDITIONS.href,
        label: 'Terms Of use',
        content: '<p class="max-w-[218px]">Learn about our policies and terms.</p>'
      }
    ]
  }
]
